<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-grid">
                <Toast />
                <div class="p-col-12 p-md-6">
                    <h5>{{ fixture.Name }}</h5>
                    <h6>{{ fixture.Date }}</h6>
                </div>
                <div class="p-col-6 p-text-right">
                    <Button @click="confirmResults()" :label="$t('buttons.save')" class="p-button-raised p-button-success p-mr-2 p-mb-2" />
                    <Button :label="$t('buttons.cancel')" class="p-button-raised p-button-danger p-mr-2 p-mb-2" @click="goto(backRoute)" />
                </div>
                <Fieldset :legend="$t('results.eventMarkets')" :toggleable="true" :collapsed="false" class="p-col-12 p-mb-2">
                    <div class="p-col-12 p-grid">
                        <div class="p-col-6 p-md-2 p-mb-2">
                            <span class="p-float-label">
                                <Dropdown id="marketsDropdown" style="width: 100%" v-model="selectedMarket" :options="markets" optionLabel="name" :filter="!this.isMobile()" />
                                <label for="marketsDropdown">{{ $t('dropdown.placeholder.market') }}</label>
                            </span>
                        </div>

                        <div class="card p-col-12 p-grid p-mb-3" v-for="(market, index) in filteredData" :key="'market' + index" v-show="!showFixtureLoading">
                            <div class="p-col-12 p-md-6">
                                <span
                                    ><b>{{ market.name }}</b> {{ market.mainLine }}</span
                                >
                            </div>
                            <div v-for="(outcome, index) in market.bets" :key="'outcome' + market.Id + '_' + outcome.Id + '_' + index" class="p-col-12 p-grid p-pb-0 p-pt-0">
                                <div class="p-col-3 p-mt-2">{{ outcome.name }} {{ outcome.baseLine != null ? '( ' + outcome.baseLine + ')' : '' }}</div>
                                <div class="p-col-3 p-mt-2">{{ outcome.price }}</div>
                                <div class="p-col-3 p-mt-2 p-text-right">
                                    <Tag :severity="getStatusColor(outcome.status)">{{ this.getStatusName(outcome.status) }} {{ outcome.status == 3 ? ' - ' + this.getSettlementName(outcome.settlement) : '' }}</Tag>
                                </div>
                                <div class="p-col-3 p-mt-0 p-text-left">
                                    <Button v-show="outcome.status == 1" :label="$t('buttons.suspend')" @click="suspendOutcome(market.id, outcome.id)" class="p-button-danger p-button-text p-mt-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fieldset>
                <Dialog :header="$t('sportsbets.dialog.updatesConfirmation')" v-model:visible="showUpdatesDialog" :breakpoints="{ '960px': '75vw' }" :style="{ width: '30vw' }" :modal="true">
                    <p v-html="modalText"></p>
                    <template #footer>
                        <Button :label="$t('buttons.close')" @click="closeModals()" icon="pi pi-times" class="p-button-danger" />
                        <Button :label="$t('buttons.confirm')" @click="addResults()" icon="pi pi-check" class="p-button-success" />
                    </template>
                </Dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { LsportsApi } from '../../service/LsportsApi';
export default {
    name: 'LiveEventDetails',
    data() {
        return {
            fixture: {
                Markets: [],
                Results: { HomeScore: '', AwayScore: '', Periods: [] },
                Name: '',
                Date: '',
            },
            LsportsApi: new LsportsApi(),
            markets: [],
            selectedMarket: { name: 'All', id: 0 },
            errorKeyCount: 0,
            showModal: false,
            showUpdatesDialog: false,
            fixtureStatusChanged: false,
            suspendedBets: [],
        };
    },
    computed: {
        filteredData() {
            let markets = [];
            if (this.selectedMarket.id == 0) {
                markets = this.fixture.Markets;
            } else {
                markets = this.fixture.Markets.filter((item) => item.id === this.selectedMarket.id);
            }
            return markets;
        },
        modalText() {
            //test
            var text = this.$t('sportsbets.dialog.saveChanges') + '<br/><br/>';
            this.suspendedBets.length > 0 ? (text += this.suspendedBets.length + ' ' + this.$t('sportsbets.dialog.suspendedOutcomes') + '<br/>') : (text += '');
            return text;
        },
    },
    created() {
        this.LsportsApi.getLiveEvent(this.$route.params.id)
            .then((response) => {
                this.fixture = response.data.fixture;
                this.fixture.Date = this.formatCSDateAndTime(response.data.fixture.startDate);
                this.fixture.Markets = response.data.markets;
                this.fixture.Name = this.getFixtureName();
                this.showFixtureLoading = false;
                var marketsArray = [];
                for (var i = 0; i < response.data.markets.length; i++) {
                    var marketObj = {
                        name: response.data.markets[i].name,
                        id: response.data.markets[i].id,
                    };
                    var marketPosition = marketsArray
                        .map(function (m) {
                            return m.id;
                        })
                        .indexOf(response.data.markets[i].id);
                    if (marketPosition < 0) {
                        marketsArray.push(marketObj);
                    }
                }
                this.markets = marketsArray.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                this.markets.unshift(this.selectedMarket);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    this.$store.commit('login/endUserSession');
                    this.goto('login', { session: 'false' });
                }
            });
    },
    watch: {
        selectedMarket() {
            if (this.selectedMarket === '') {
                this.selectedMarket = { name: 'All', id: '0' };
            }
        },
    },
    props: {
        backRoute: {
            type: String,
        },
    },
    methods: {
        getStatusColor(status) {
            switch (status) {
                case 3: {
                    return 'warning';
                }
                case 1: {
                    return 'success';
                }
                case 2: {
                    return 'danger';
                }
            }
        },
        showToast(toastSeverity, text) {
            this.$toast.add({ severity: toastSeverity, summary: '', detail: text, life: this.getToastLifeSeconds() });
        },
        confirmResults() {
            if (this.suspendedBets.length > 0) {
                this.showUpdatesDialog = true;
            } else {
                this.showToast('info', this.$t('sportsbets.alerts.noChanges'));
            }
        },
        addResults() {
            this.showUpdatesDialog = false;
            this.LsportsApi.suspendBets(this.suspendedBets, this.$route.params.id)
                .then(() => {
                    this.showToast('success', this.$t('sportsbets.alerts.changesDone'));
                })
                .catch((error) => {
                    this.checkForUnauthorizedError(error);
                    this.showToast('error', this.$t('alerts.generalError'));
                });
        },
        getFixtureName() {
            let name = '';
            if (this.fixture.participants != null) {
                name = this.fixture.participants[0].name;
                if (this.fixture.participants.length > 1) {
                    name += ' vs.  ' + this.fixture.participants[1].name;
                }
            }
            return name;
        },
        getStatusName(status) {
            switch (status) {
                case 1: {
                    return 'Open';
                }
                case 2: {
                    return 'Suspended';
                }
                case 3: {
                    return 'Settled';
                }
            }
        },
        getSettlementName(settlement) {
            switch (settlement) {
                case -1: {
                    return 'Cancelled';
                }
                case 1: {
                    return 'Loser';
                }
                case 2: {
                    return 'Winner';
                }
                case 3: {
                    return 'Refund';
                }
                case 4: {
                    return 'HalfLost';
                }
                case 5: {
                    return 'HalfWon';
                }
                case 0: {
                    return 'Not Settled';
                }
            }
        },
        suspendOutcome(marketId, outcomeId) {
            var market = this.fixture.Markets.find((m) => m.id == marketId);
            var bet = market.bets.find((b) => b.id == outcomeId);
            bet.status = 2;
            var newSuspendedOutcome = { MarketId: marketId, OutcomeId: outcomeId };
            this.suspendedBets.push(newSuspendedOutcome);
        },
        closeModals() {
            this.showUpdatesDialog = false;
        },
    },
};
</script>
